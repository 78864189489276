.login-header {
  // no styles
}

.btn-login {
  color: #fff;
  background-color: #00BFA6;
  border-color: #02c3ab;
}

.btn-login:hover {
  color: #fff;
  background-color: #009f8b;
  border-color: #00917e;
}
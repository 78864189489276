.App {
  text-align: center;
}

.feather {
  width: 100px;
  height: 100px;
  stroke: currentColor;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: rgba(255, 255, 255, 0.76);
}

img.audiobook-album {
  max-width: 100%;
}

.view {
  position: relative;
  overflow: hidden;
  cursor: default;

  & .mask {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-attachment: fixed;
  }

  &:hover .mask {
    display: flex;
  }
}
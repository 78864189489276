.room {
  min-height: 15em;
  background-color: white;
  color: black;

  &> img {
    max-height: 15em;
  }

  &.selected {
    border: 3px solid #0c9e9e;
  }
}
.back-button {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 86.6px 50px 0;
  border-color: transparent #04b37e transparent transparent;

  &.small {
    border-width: 25px 43.3px 25px 0
  }
}